/**
 * Common file for global constant values
 * Refactor this file into a constants folder
 * with mutiple constants files, if it gets too big
 */

export enum USER_TYPE {
    intranet = 'intranet', // intranet login
    internet = 'internet', // internet login
}

export enum OPS_UNIT_TYPE {
    PSA = 'PSA',
    NGE_BIP = 'NGE (BIP)',
    NGE_NON_BIP = 'NGE (Non-BIP)',
}

export enum AGENCY_TYPE {
    PSA = 'PSA', // Public Sector Agency, as Saved in DB
    NGE = 'NGE', // Non Government Enitity
}

export enum ACCESS_LEVELS {
    LEAD_SYSTEM_ADMINISTRATOR = 'LeadSystemAdministrator',
    SYSTEM_ADMINISTRATOR = 'SystemAdministrator',
    AGENCY_ADMINISTRATOR = 'AgencyAdministrator',
    OPSUNIT_ADMINISTRATOR_APP_USER = 'OpsUnitAdministratorAppUser',
    OPSUNIT_ADMINISTRATOR = 'OpsUnitAdministrator',
    TEAM_ADMINISTRATOR_APP_USER = 'TeamAdministratorAppUser',
    TEAM_ADMINISTRATOR = 'TeamAdministrator',
    DATA_UPLOADER_APP_USER = 'DataUploaderAppUser',
    DATA_UPLOADER = 'DataUploaderOnly',
    APP_USER = 'AppUser',
    UNKNOWN_USER = 'UnknownUser',
}

export const ACCESS_LEVELS_ROLE: {[key: string]: string} = {
    LeadSystemAdministrator: 'Lead System Administrator',
    SystemAdministrator: 'System Administrator',
    AgencyAdministrator: 'Agency Administrator',
    OpsUnitAdministrator: 'Ops Unit Administrator',
    TeamAdministrator: 'Team Administrator',
    OpsUnitAdministratorAppUser: 'Ops Unit Administrator',
    TeamAdministratorAppUser: 'Team Administrator',
    AppUser: 'User',
    DataUploaderOnly: 'Data Contributor',
    UnknownUser: 'User',
};

export enum LOGIN_STATE {
    LOGGED_IN = 'LoggedIn',
    LOGGED_OUT_MANUAL = 'LoggedOutManual',
    LOGGED_OUT_INACTIVE = 'LoggedOutInactive',
    LOGGED_OUT_EXPIRED = 'LoggedOutInExpired',
    LOGGED_OUT_REDIRECT = 'LoggedOutRedirect',
}

export enum API_FETCH_STATE {
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
    ERROR = 'ERROR',
}

export enum ACCESS_REASON {
    UNDERSTAND_CHILD_PRESENTING_ISSUES = `UNDERSTAND_CHILD_PRESENTING_ISSUES`,
    LOCATE_CLIENT_FAMILY_MEMBER = `LOCATE_CLIENT_FAMILY_MEMBER`,
    CHECK_CLIENT_ELIGIBILITY = `CHECK_CLIENT_ELIGIBILITY`,
    APPLICATION_PURPOSES = `APPLICATION_PURPOSES`,
    OTHERS = `OTHERS`,
}

export enum CONSENT_TYPE {
    ONECV_CONSENT = 'ONECV_CONSENT',
    ONECV_MI_CONSENT = 'ONECV_MI_CONSENT',
    ONECV_MINOR_CONSENT = 'ONECV_MINOR_CONSENT',

    SSNET_CONSENT = 'SSNET_CONSENT',
    SSNET_MI_CONSENT = 'SSNET_MI_CONSENT',
    SSNET_MINOR_CONSENT = 'SSNET_MINOR_CONSENT',

    AGENCY_CONSENT = 'AGENCY_CONSENT',
    SKIP_CONSENT = 'SKIP_CONSENT',
    NOT_SELECTED = 'NOT_SELECTED',
}

export enum CONSENT_STATUS {
    ACTIVE_CONSENT = 'ACTIVE',
    DELETED_CONSENT = 'DELETED',
    REVOKED_CONSENT = 'REVOKED',
    EXPIRED_CONSENT = 'EXPIRED',
    ONE_TIME_CONSENT = 'ONE_TIME',
}

export enum CONSENT_ACTIONS {
    REVOKE = 'revoke',
    DELETE = 'delete',
    UPDATE = 'update',
}

export enum ERROR_MESSAGE {
    GENERIC_SERVER_ERROR = 'Unexpected Server Error: Please try again later',
    CLIENT_CONSENT_NOT_FOUND_ERROR = 'No consent found for the client',
}

export enum SUBDOMAIN_RESULT_MESSAGE {
    EMPTY = 'Request successful (No results found)',
}

export enum ENVIRONMENT_NAMES {
    LOCAL = 'development',
    QA = 'dev',
    PRODUCTION = 'prod',
    UAT = 'uat',
}

export enum REPORT_TYPE {
    DATA_ACCESS_REPORTS = 'DataAccessReports',
    SCREENING_BY_USERS = 'ScreeningsbyUsers',
    USER_REPORT = 'UserReport',
    MONTHLY_REVIEW_REPORT = 'MonthlyReviewReport',
    MONTHLY_REVIEW_ADMIN_REPORT = 'MonthlyReviewAdminReport',
    SURVEYS_RESULTS_REPORT = 'SurveysResultsReport',
    IRREGULAR_ACTIVITIES_REPORT = 'IrregularActivitiesReport',
    DAM_REPORT = 'DamReport',
    DAM_REPORT_OA = 'DamReportForOA',
    DAM_REPORT_AA = 'DamReportForAA',
    CONSENT_AUDIT_REPORT = 'ConsentAuditReport',
    API_CONSUMER_REQUESTS_REPORT = 'APIConsumerRequestsReport',
    DELETION_REPORT = 'DeletionReport',
}

export enum LOGOUT_MESSAGE {
    INACTIVE = 'Session has expired. Please log in again.',
    MANUAL = 'You are now logged out. We hope to see you again!',
    REDIRECT = 'You have been logged out as a newer session is detected.',
}

export enum FIELD_VALUES {
    UNSUCCESSFUL = 'Unsuccessful',
    NO_ACCESS = '(No access)',
    NO_RESULTS = '(No results)',
}

export enum SCREENING_STATUS {
    UNSUCCESSFUL = '(Unsuccessful)',
    PENDING = '(Pending)',
}

export const NAVBAR_PADDING = 122; // Navbar height + result tab height
export const THRESHOLD_RATIO = 0.3;
export enum LOADING_MESSAGE {
    DATA = 'Loading Data',
}
export enum COPIES {
    DO_NOT_SHOW_AGAIN = 'Do not show this message again',

    ADD_SUBDOMAIN_TITLE = 'Added all the sub-domains you need?',
    ADD_SUBDOMAINS_CONFIRMATION = 'Please ensure you have selected all the sub-domains required. We’ll like to save you the hassle of going through another new screening.',
    SUBDOMAIN_NOT_GRANTED_MESSAGE = 'Permission for this subdomain has not been granted. Please contact your team administrator for access.',

    ADDED_ALL_TEXT = `YES, I'VE ADDED ALL!`,
    FETCHING_INFO = 'Hold on tight, information coming soon!',
    FOOTER_BANNER = `This website is best viewed in Google Chrome and when your laptop's display setting is at 100%.`,

    REPORT_PASSWORD_DIALOG_TITLE = "We're only showing it to you and you only",
    REPORT_PASSWORD_DIALOG_BODY = 'Please set up a password for the report you are generating. To strengthen your password security, your password should contain the following:',
    REPORT_PASSWORD_DIALOG_CHAR_COUNT = 'Has 12-20 characters',
    REPORT_PASSWORD_DIALOG_NUM = 'Contains at least one number',
    REPORT_PASSWORD_DIALOG_CAPITAL = 'Contains at least one capital letter',
    REPORT_PASSWORD_DIALOG_SPECIAL = 'Contains at least one special character (e.g. %, $, #)',
    REPORT_PASSWORD_DIALOG_DOWNLOAD = 'DOWNLOAD',

    UPLOAD_PASSWORD_DIALOG_TITLE = 'Set a Password for Summary Report',
    UPLOAD_PASSWORD_DIALOG_BODY = 'A TXT File containing a summary of the uploaded records will be returned to you, ZIP-ed for password protection. Please set a password for this ZIP file.',

    DOWNLOAD_FORM_TITLE = 'This form contains confidential information. For security purposes, please set up a password that: ',
    DOWNLOAD_REPORT_TITLE = 'This report contains confidential information. For security purposes, please set up a password that: ',

    REPORT_CUSTOMISATION_DIALOG_TITLE = "We'll like you to receive what you need.",
    REPORT_CUSTOMISATION_DIALOG_BODY = 'Select the information you would like to have in the generated report.',
    REPORT_CUSTOMISATION_CHECK_ALL = 'All Domains',
    REPORT_CUSTOMISATION_DIALOG_BUTTON = 'NEXT',
    REPORT_CUSTOMISATION_ERROR = 'Please select at least one sub-domain.',
    REPORT_CUSTOMISATION_STEP_ERROR = 'An error has occurred, please refresh page.',

    REPORT_FAMILY_CUSTOMISATION_DIALOG_TITLE = 'Report Customisation',

    FSR_MULTIPLE_HITS_API_ERROR = `Multiple Hits for ID Number <'[REDACTED]'>. Please enter DoB.`,
    FSR_MULTIPLE_HITS_INFO_MESSAGE = 'We are working with the data source to address this issue.',
    FSR_NOT_FOUND_INFO_MESSAGE = 'Information of the client is not available.',
    FSR_DOWN_INFO_MESSAGE = `Client's profile and Immediate Family Members are not available presently.`,

    CLIENT_INFO_ERROR = `There was an error retrieving the client’s information. Please report this to us via ‘Contact Us’, or try again later. `,

    PARTICIPATING_AGENCIES_TITLE = 'List of Participating Agencies',
    USER_AGENCIES_TITLE = 'User Agencies',
    USER_AGENCIES_DESCRIPTION = 'This is the list of agencies that are using OneCV.',
    DATA_AGENCIES_TITLE = 'Data Contributing Agencies',
    DATA_AGENCIES_DESCRIPTION = 'This is the list of agencies that have contributed data to OneCV.',

    NO_ANNOUNCEMENT_MESSAGE = 'There is no announcement presently.',

    SEARCH_CONFIRMATION_TITLE = `Checked your client's NRIC/FIN?`,
    SEARCH_CONFIRMATION_MESSAGE = 'Please check if you have searched for the right NRIC/FIN.',
    SEARCH_CONFIRMATION_BUTTON_TEXT = `YES, I'VE CHECKED THE NRIC/FIN!`,

    HAS_MEDIFUND_ASSISTANCE = 'Client received Medifund Assistance over the last 12 months.',
    NO_MEDIFUND_ASSISTANCE = 'Client did not receive Medifund Assistance over the last 12 months.',
    NO_MEDICAL_EXPENSE_RECORDS = 'No medical expense records were found',

    MONTHLY_REVIEW_CONFIRMATION_TITLE = `Submit review?`,
    MONTHLY_REVIEW_CONFIRMATION_MESSAGE = 'By clicking ‘Mark as reviewed’, you have checked that all users are authorised to use OneCV as at',
    SUBMIT_MONTHLY_REVIEW_MESSAGE = 'Your review has been submitted to your zz.',

    CONSENT_TITLE = 'Consent for Selected Sub-Domain(s)',
    CONSENT_SUBTITLE = 'You will need to obtain consent from the following person(s) before you can view the selected sub-domain(s):',
    CONSENT_REVOKE_DESCRIPTIONS = 'All officers will have to re-indicate consent when they do a screening for this client.',

    CONSENT_UPDATE_DESCRIPTIONS = 'No changes will be made to the revoked consent.',
    CONSENT_UPDATE_ACTIVE_DESCRIPTIONS = 'No changes will be made to the consent indicated by the officer.',

    ACCESS_REASON_TITLE = 'Reasons for Access',
    ACCESS_REASON_SUBTITLE = "We protect our clients' privacy. Please indicate the reasons to access these sub-domains.",
    APPLY_ALL_REASONS_MESSAGE = 'The reason(s) for request have been applied to all family members',
    APPLY_ALL_BUTTON = 'APPLY SELECTION TO ALL',

    CONSENT_OMNIBUS = 'OneCV (Omnibus) consent given',
    CONSENT_SSNET = 'Consent uploaded on SSNet (SSNet users only)',
    CONSENT_SSNET_DESCRIPTION = 'Person has given consent and it has been uploaded on SSNet',
    CONSENT_AGENCY = 'Agency-specific consent given only',
    CONSENT_AGENCY_DESCRIPTION = 'Person has given consent to your agency only. You will be able to vie the selected sub-domain(s) for this screening only. You will need to indicate consent for subsequent screenings.',
    CONSENT_NONE = 'Consent has not been given',
    CONSENT_NONE_DESCRIPTION = 'Person has not given consent. You will not be able to view the selected sub-domain(s).',

    DELETE_FILE_IMMEDIATELY = 'The file will be deleted immediately. You will not be able to undo this action.',

    DECEASED = '(Deceased)',
    COMCARE1_IND_YES = 'Client is a current recipient of ComCare Assistance (Long-Term Assistance or Short-to-Medium Term Assistance).',
    COMCARE1_IND_NO = 'Client is not a current recipient of ComCare Assistance (Long-Term Assistance or Short-to-Medium Term Assistance).',
    COMCARE_FIXED_MESSAGE = 'Table displays records from the last 2 years.',
}

export const consentTypePrint = (consentType: CONSENT_TYPE) => {
    switch (consentType) {
        case CONSENT_TYPE.ONECV_MINOR_CONSENT:
        case CONSENT_TYPE.SSNET_MINOR_CONSENT:
            return 'on behalf of client (minor)';
        case CONSENT_TYPE.ONECV_MI_CONSENT:
        case CONSENT_TYPE.SSNET_MI_CONSENT:
            return 'on behalf of client (mentally incapacitated)';
        default:
            return 'by client';
    }
};

export const consentClause = (agency: string | undefined, consentType: CONSENT_TYPE) =>
    `Consent provided ${consentTypePrint(consentType)} through ${agency}`;

export const SURVEY_IMPROVEMENTS: {[key: string]: string} = {
    EASE_OF_USE: 'Ease of use',
    PERFORMANCE: 'System performance',
    TECHNICAL_ISSUES: 'Technical issues',
    INSTRUCTIONS: 'Instructions',
    OTHERS: 'Others (Indicate in the text box below)*',
};

export const FORM_MAX_TEXT = 300;

export enum SURVEY_TYPE {
    MONTHLY = 'MONTHLY_SURVEY',
    FIRST_SCREENING = 'FIRST_SCREENING_SURVEY',
}

export const SOURCE_SYSTEM_TYPE_WAITING_PERIOD: {[key: string]: string} = {
    API: 'Immediate',
    SFTP: '1-2 Days',
    'API/SFTP': '1-2 Days',
};

export const ALLOWED_UPLOAD_MIME_TYPES: string[] = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const ALLOWED_UPLOAD_EXTENSIONS: string[] = [
    '.pdf',
    '.doc',
    '.docx',
    '.jpeg',
    '.jpg',
    '.xls',
    'xlsx',
    'ppt',
    'pptx',
];

export const INITIAL_SUBDOMAIN_DATA_DISPLAY_LENGTH = 3;

export enum Relationship {
    WIFE = 'Wife',
    HUSBAND = 'Husband',
    SON = 'Son',
    DAUGHTER = 'Daughter',
    FATHER = 'Father',
    MOTHER = 'Mother',
    BROTHER = 'Brother',
    SISTER = 'Sister',
    UNKNOWN = 'Unknown Relationship',
    CLIENT = 'Client',
}

export const RelationshipOrder = [
    Relationship.WIFE,
    Relationship.HUSBAND,
    Relationship.SON,
    Relationship.DAUGHTER,
    Relationship.FATHER,
    Relationship.MOTHER,
    Relationship.BROTHER,
    Relationship.SISTER,
    Relationship.UNKNOWN,
];

export const CaseStatusOrder = ['Open', 'Closed'];

export const ALLOWED_IMAGE_MIME_TYPES: string[] = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg'];

// This may serve as an interim solution until the solution for modifying data subdomains
// by business side is taken into consideration.
export const SUBDOMAIN_ADDITIONAL_INFO: {[subdomainName: string]: string} = {
    'CPF Account Details':
        'Account Balances, Contribution and Withdrawal Details, Housing Payment Info, Nomination Status',
};

export enum ReportType {
    SINGLE_PDF = '1 PDF',
    SEPARATE_PDF = 'Separate PDFs',
}

export const ADVISORY_NOTE_1 =
    'Please do not share family relationships information with any other individual including your client.';
export const ADVISORY_NOTE_2 =
    'Family relationships and marital information are derived from raw data provided by the relevant agencies. You are advised to perform your own verification and checks or may use the information at your own discretion.';

export const FIRST_TIME_ONBOARDING = 'first_time_onboarding';

export enum USER_FLAGS {
    FIRST_TIME_ONBOARDING = 'HasAckFirstTimeOnboarding',
    CLIENT_CONSENT = 'HasAckClientConsent',
    ACCESS_REASONS = 'HasAckAccessReasons',
    SELECT_SUBDOMAIN_REMINDER = 'HasCheckedAddedAllSubdomainReminder',
}

export const ERROR_COPY = 'Unsuccessful. Please check with your administrator.';
export const NO_RESULT_COPY = '(No results)';

export const DEFAULT_SHORT_FIELD_MAX_LENGTH = 120;
export const DEFAULT_LONG_FIELD_MAX_LENGTH = 500;
export const ONECV_LAUNCH_DATE = new Date(2021, 1, 21);

export const SUPPORT_EMAIL = 'EUSS_SERVICEDESK@support.gov.sg';
export const SECONDARY_SUPPORT_EMAIL = 'ONECV_SUPPORT@psd.gov.sg';
export const LEARN_MORE_LINK = '/guides-and-toolkits';

export const MAIL_TO_LINK =
    `mailto:${SUPPORT_EMAIL}?` +
    `cc=${SECONDARY_SUPPORT_EMAIL}&` +
    `subject=[OneCV Users] - [Please provide a descriptive email title here]&` +
    `body=` +
    `Hi OneCV Support%0D%0A%0D%0A` +
    `Indicate Issue - (e.g.  I am unable to access OneCV)%0D%0A%0D%0A` +
    `Describe Issue - (e.g. My supervisor provided me with access but I was not able to access the system. I encountered an error message.)%0D%0A%0D%0A` +
    `Date and Time of Issue - (e.g. Around 10.30am on 1 Jan 2021)%0D%0A%0D%0A` +
    `Provide screenshot(s)%0D%0A` +
    `*Please do not provide identifiable data or full NRICs in your screenshot. If you need to provide such information, please password-protect the file when sending over.%0D%0A%0D%0A` +
    `Provide your contact details - (e.g. Name: Mr Jason Tan, Contact Number: 9111 1111)`;

export enum MONTHLY_REVIEW_STATUS {
    ONGOING = 'ONGOING',
    COMPLETED = 'COMPLETED',
    INCOMPLETE = 'INCOMPLETE',
    NEW = 'NEW',
    ERROR = 'ERROR',
}

export const MONTHLY_REVIEW_COMPLETED_MESSAGE: {[key: string]: string} = {
    TeamAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    TeamAdministratorAppUser: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    OpsUnitAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    OpsUnitAdministratorAppUser: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    AgencyAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    LeadSystemAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    SystemAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
};

export const MONTHLY_REVIEW_INCOMPLETED_MESSAGE: {[key: string]: string} = {
    TeamAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    TeamAdministratorAppUser: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    OpsUnitAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    OpsUnitAdministratorAppUser: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    AgencyAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    LeadSystemAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
    SystemAdministrator: `Please continue to update user accounts. Users who are no longer authorised to use OneCV should be removed within five working days from their last authorised use (e.g. left agency/department/branch).`,
};

export const MONTHLY_REVIEW_USER_ONGOING_MESSAGE: {[key: string]: string} = {
    TeamAdministrator: `1. Please ensure all user accounts are updated.\n2. All Teams (with or without users) have to complete this review to confirm Team is updated. \n 3. Agency Admin has to approve the review of your Ops Unit by 21`,
    TeamAdministratorAppUser: `1. Please ensure all user accounts are updated.\n2. All Teams (with or without users) have to complete this review to confirm Team is updated. \n 3. Agency Admin has to approve the review of your Ops Unit by 21`,
    OpsUnitAdministrator: `1. Please check that Ops Unit Admin(s) are active and authorised.\n2. Ensure all user accounts are updated.\n3. Agency Admin has to approve the review of your Ops Unit by 21`,
    OpsUnitAdministratorAppUser: `1. Please check that Ops Unit Admin(s) are active and authorised.\n2. Ensure all user accounts are updated.\n3. Agency Admin has to approve the review of your Ops Unit by 21`,
};

export const MONTHLY_REVIEW_ADMIN_ONGOING_MESSAGE: {[key: string]: string} = {
    OpsUnitAdministrator: `1. Please check that Ops Unit Admins(s) are active and authorised.\n2. Ensure all Team Admins are updated.\n3. All teams have to be reviewed by their Team Admin before you can click 'Mark as reviewed'.\n4. Agency Admin has to approve the review of your Ops Unit by 21`,
    OpsUnitAdministratorAppUser: `1. Please check that Ops Unit Admins(s) are active and authorised.\n2. Ensure all Team Admins are updated.\n3. All teams have to be reviewed by their Team Admin before you can click 'Mark as reviewed'.\n4. Agency Admin has to approve the review of your Ops Unit by 21`,
    AgencyAdministrator: `1. Please acknowledge that Agency Admins(s) are active and authorised by clicking 'Mark as active'.\n2. Ensure all Ops Unit Admins are updated.\n3. Click 'Approve' to confirm that all user accounts in the Ops Unit are authorised users of OneCV.`,
    LeadSystemAdministrator: `1. Please ensure all user accounts are updated.\n2. All user accounts should be reviewed by 21`,
    SystemAdministrator: `1. Please ensure all user accounts are updated.\n2. All user accounts should be reviewed by 21`,
};

export const DELETE_REASONS: {[key: string]: string} = {
    Team: `I understand that I am deleting this Team permanently. All Team Admins and Users that are part of this Team will be deleted and no longer have access to OneCV. `,
    OpsUnit: `I understand that I am deleting this Ops Unit permanently. All Teams, Admins and Users that are part of this Ops Unit will be deleted and no longer have access to OneCV.`,
    Agency: `I understand that I am deleting this Agency permanently. All Ops Units, Teams, Admins and Users that are part of this Agency will be deleted and no longer have access to OneCV.`,
};

export const familyMembersPerScreeningLimit = 3;

export const notificationsLimit = 30;

export const oneCVStartDay = 'Jan 11 2021';

export const oneCVAPIProviderStartDay = 'June 01 2022';

export const oneCVMonthlyAdminReviewStartDay = 'August 1 2022';

interface IIdleTimeout {
    FIRST_IDLE_TIMEOUT_IN_MINS: number;
    SECOND_IDLE_TIMEOUT_IN_MINS: number;
}

export const idleTimeout: IIdleTimeout = {
    FIRST_IDLE_TIMEOUT_IN_MINS: 15,
    SECOND_IDLE_TIMEOUT_IN_MINS: 5,
};
